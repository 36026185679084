import { MYSTIC_40, OwnUpBody, PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const Body = styled(OwnUpBody)`
  padding-left: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(3)}px`};
`;

export const Container = styled.div`
  background-color: ${MYSTIC_40};
  border-radius: 8px;
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4, 3)};
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4, 6)};
  }
`;

export const ValuePropsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(4)}px`};
`;

export const ValuePropWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
