import {
  ALOE_100,
  FOG_40,
  MINT_100,
  PropsWithTheme,
  WHITE_100
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { Ankle } from '../components/csma/ankle';
import { ConnectWithLender } from '../components/csma/connect-with-lender';
import { HeroConcierge } from '../components/csma/hero';
import { HowItWorks } from '../components/csma/how-it-works';
import { RdcLandingPageLayout } from '../components/csma/layout';
import { RdcSection } from '../components/csma/layout/elements';
import { DisplayOnDesktop, DisplayOnMobile } from '../components/csma/shared';
import { WhyWorkWithUs } from '../components/csma/why-work-with-us';
import { YcbmSchedule } from '../components/csma/ycbm';
import { valuePropsConcierge } from '../data/content/csma/value-props';

/**
 * Applying this additional padding on the containers specific to this page
 * so the non-concierge page is not affected.
 */
const DisplayOnDesktopWithPadding = styled(DisplayOnDesktop)`
  padding-bottom: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
`;

const DisplayOnMobileWithPadding = styled(DisplayOnMobile)`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(4)}px`};
`;

export const RealtorCsmaCp = () => (
  <RdcLandingPageLayout>
    <RdcSection backgroundColor={ALOE_100}>
      <HeroConcierge />
    </RdcSection>
    <RdcSection
      style={{
        background: `linear-gradient(to bottom, ${ALOE_100} 25%, ${WHITE_100} 25% 100%)`
      }}
    >
      <DisplayOnDesktopWithPadding>
        <HowItWorks title="Book now and let us shop for you!" valueProps={valuePropsConcierge} />
      </DisplayOnDesktopWithPadding>
    </RdcSection>
    <YcbmSchedule />
    <RdcSection backgroundColor={WHITE_100}>
      <DisplayOnMobileWithPadding>
        <HowItWorks title="Book now and let us shop for you!" valueProps={valuePropsConcierge} />
      </DisplayOnMobileWithPadding>
      <WhyWorkWithUs />
    </RdcSection>
    <RdcSection backgroundColor={MINT_100}>
      <ConnectWithLender />
    </RdcSection>
    <RdcSection backgroundColor={FOG_40}>
      <Ankle />
    </RdcSection>
  </RdcLandingPageLayout>
);

export default RealtorCsmaCp;
