import { ALOE_100, OwnUpIcon } from '@rategravity/own-up-component-library';
import React from 'react';
import { ScaleIcon } from '../../../images/csma/compare';
import { OfferIcon } from '../../../images/csma/offer';
import { ProfileIcon } from '../../../images/csma/profile';

export const valuePropsConcierge = [
  {
    icon: <OwnUpIcon icon={ProfileIcon} color={ALOE_100} height={2} />,
    text: 'Take advantage of industry expert advice —at no cost to you. Experience personalized, mortgage shopping without inconvenience.'
  },
  {
    icon: <OwnUpIcon icon={ScaleIcon} height={2} />,
    text: 'Anonymously shop your profile across multiple lenders and compare your offers in one place, apples-to-apples.'
  },
  {
    icon: <OwnUpIcon icon={OfferIcon} color={ALOE_100} height={2} />,
    text: 'Unlock exclusive, competitive rates! We work behind the scenes, to negotiate the best rates for you.'
  }
];
