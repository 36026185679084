import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpLargeBody
} from '@rategravity/own-up-component-library';
import React from 'react';
import { BrennaImage } from '../../static-images/shared';
import { DisplayOnDesktop, DisplayOnMobile } from '../shared';
import { Container, Headline, PictureText, Section } from './elements';

export const WhyWorkWithUs = () => {
  const introText =
    'Finding the right mortgage can take time and effort —there are so many options!';
  return (
    <OwnUpGridContainer variant="slim">
      <OwnUpGridItem xs={12}>
        <Headline variant="h2">Why work with Own Up?</Headline>
        <Section>
          <DisplayOnMobile>
            <OwnUpLargeBody variant="body1">{introText}</OwnUpLargeBody>
          </DisplayOnMobile>
          <Container xs={9} sm={8} md={5}>
            <BrennaImage />
            <PictureText variant="body1">
              Pictured: Brenna O&apos;Donnell, one of our home advisors
            </PictureText>
          </Container>
          <Container xs={12} md={6}>
            <DisplayOnDesktop>
              <OwnUpLargeBody variant="body1">{introText}</OwnUpLargeBody>
              <br />
            </DisplayOnDesktop>
            <OwnUpLargeBody variant="body1">
              It&apos;s no wonder that over 60% of people feel overwhelmed when shopping for a
              mortgage. How can you be sure you&apos;re making the best decision?
            </OwnUpLargeBody>
            <br />
            <OwnUpLargeBody variant="body1">
              We take all the guesswork out of finding a great lender by matching you based on your
              needs and goals.
            </OwnUpLargeBody>
          </Container>
        </Section>
      </OwnUpGridItem>
    </OwnUpGridContainer>
  );
};
