import { OwnUpSmallSubheadlineRegular } from '@rategravity/own-up-component-library';
import React from 'react';
import { Body, Container, ValuePropsWrapper, ValuePropWrapper } from './elements';

interface ValueProps {
  icon: JSX.Element;
  text: string;
}

const ValueProps = ({ valueProps }: { valueProps: ValueProps[] }) => (
  <ValuePropsWrapper>
    {valueProps.map(({ icon, text }, i) => (
      <ValuePropWrapper key={i}>
        {icon}
        <Body variant="body1">{text}</Body>
      </ValuePropWrapper>
    ))}
  </ValuePropsWrapper>
);

export const HowItWorks = ({ title, valueProps }: { title: string; valueProps: ValueProps[] }) => (
  <Container>
    <OwnUpSmallSubheadlineRegular variant="h1">{title}</OwnUpSmallSubheadlineRegular>
    <ValueProps valueProps={valueProps} />
  </Container>
);
