import {
  OwnUpFloatingModal,
  OwnUpLargeBody,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4, 0)};
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2, 0)};
`;

export const ConnectedModal = ({
  open,
  setOpen
}: {
  open: boolean;
  setOpen: (_: boolean) => void;
}) => (
  <OwnUpFloatingModal
    open={open}
    onClose={() => setOpen(false)}
    title={'Good news —We found you a lender.'}
  >
    <OwnUpLargeBody variant="body1">
      You can expect a call or email directly from our lender. They will take it from here!
    </OwnUpLargeBody>
  </OwnUpFloatingModal>
);
