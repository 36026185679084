import { ALOE_100 } from '@rategravity/own-up-component-library';
import React from 'react';

export const OfferIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1081 26.818L24.108 15.818C24.3893 15.5367 24.5473 15.1552 24.5474 14.7574L24.5474 11.7986H22.4466C22.3365 12.2498 22.1052 12.6774 21.7529 13.0298C20.7277 14.0549 19.0657 14.0549 18.0405 13.0298C17.0154 12.0047 17.0154 10.3426 18.0405 9.31747C19.0657 8.29234 20.7277 8.29234 21.7529 9.31747C22.1052 9.66984 22.3365 10.0975 22.4466 10.5486H24.5474L24.5474 8.00002C24.5474 7.17158 23.8759 6.5 23.0474 6.5L16.29 6.5C15.8922 6.5 15.5106 6.65804 15.2293 6.93934L4.22938 17.9393C3.64359 18.5251 3.64359 19.4749 4.22938 20.0607L10.9867 26.818C11.5725 27.4038 12.5223 27.4038 13.1081 26.818ZM26.0474 10.5486L26.0474 8.00003C26.0474 6.34317 24.7043 5 23.0474 5L16.29 5C15.4943 5 14.7313 5.31607 14.1687 5.87869L3.16871 16.8787C1.99714 18.0503 1.99715 19.9497 3.16872 21.1213L9.92608 27.8787C11.0977 29.0502 12.9971 29.0502 14.1687 27.8787L25.1687 16.8787C25.7313 16.3161 26.0473 15.553 26.0473 14.7574L26.0474 11.7986H29.0891C29.4343 11.7986 29.7141 11.5188 29.7141 11.1736C29.7141 10.8284 29.4343 10.5486 29.0891 10.5486H26.0474ZM21.1219 10.5486C21.058 10.4235 20.9737 10.3061 20.869 10.2014C20.332 9.66438 19.4614 9.66438 18.9244 10.2014C18.3875 10.7383 18.3875 11.6089 18.9244 12.1459C19.4614 12.6829 20.332 12.6829 20.869 12.1459C20.9737 12.0412 21.058 11.9238 21.1219 11.7986H19.8967C19.5515 11.7986 19.2717 11.5188 19.2717 11.1736C19.2717 10.8284 19.5515 10.5486 19.8967 10.5486H21.1219Z"
      fill={ALOE_100}
    />
  </svg>
);
