import { useLocation } from '@reach/router';
import { parse, stringify } from 'querystring';
import React, { useEffect, useState } from 'react';
import { Container } from './elements';

export const getYcbmQueryString = (search: string) => {
  const { name, email, phone } = parse(search);
  const nonEmptyFields = {
    ...(name ? { NAME: name } : {}),
    ...(email ? { EMAIL: email } : {}),
    ...(phone ? { PHONE: phone } : {}),
    noframe: true,
    skipHeaderFooter: true
  };
  return stringify(nonEmptyFields);
};

export const YcbmSchedule = () => {
  const location = useLocation();
  const search = location.search.substring(1);
  const [link, setLink] = useState('');

  // Because the page is built statically, need to grab the
  // url params in useEffect or they will be blank
  useEffect(() => {
    const queryString = getYcbmQueryString(search);
    setLink(`https://ownup-realtor-csma.youcanbook.me/?${queryString}`);
  }, [search]);

  return (
    <Container>
      <iframe src={link} title="ycbm iframe" />
    </Container>
  );
};
