import { ALOE_100 } from '@rategravity/own-up-component-library';
import React from 'react';

export const ScaleIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.494 8.30261C18.421 9.3438 17.7103 10.2091 16.75 10.5106L16.75 24.875H20.0002C20.4145 24.875 20.7502 25.2108 20.7502 25.625C20.7502 26.0392 20.4145 26.375 20.0002 26.375H12.0002C11.586 26.375 11.2502 26.0392 11.2502 25.625C11.2502 25.2108 11.586 24.875 12.0002 24.875H15.25V10.5105C14.6639 10.3263 14.1708 9.93213 13.859 9.41612L9.15026 10.206L12.4809 16.8673C12.6633 17.2322 12.7968 17.6884 12.7386 18.1837C12.4616 20.5441 10.4558 22.375 8.02123 22.375C5.5867 22.375 3.58087 20.5441 3.30382 18.1837C3.24568 17.6884 3.37911 17.2322 3.56155 16.8673L7.35041 9.28959C7.47694 9.03652 7.73507 8.87629 8.01781 8.87501L13.506 7.95438C13.5937 6.65325 14.6769 5.625 16.0002 5.625C16.9111 5.625 17.7083 6.11216 18.1453 6.84016L23.7935 5.89269C23.817 5.88874 23.8404 5.88594 23.8638 5.88423C23.9022 5.87814 23.9415 5.875 23.9812 5.875C24.2653 5.875 24.525 6.0355 24.652 6.28959L28.4409 13.8673C28.6233 14.2322 28.7567 14.6884 28.6986 15.1837C28.4215 17.5441 26.4157 19.375 23.9812 19.375C21.5467 19.375 19.5408 17.5441 19.2638 15.1837C19.2056 14.6884 19.3391 14.2322 19.5215 13.8673L22.6527 7.60501L18.494 8.30261ZM17.0002 8.125C17.0002 8.67728 16.5525 9.125 16.0002 9.125C15.448 9.125 15.0002 8.67728 15.0002 8.125C15.0002 7.57272 15.448 7.125 16.0002 7.125C16.5525 7.125 17.0002 7.57272 17.0002 8.125ZM21.1947 13.875L23.9812 8.30205L26.7677 13.875H21.1947ZM20.818 15.375C21.1563 16.8081 22.4446 17.875 23.9812 17.875C25.5178 17.875 26.8061 16.8081 27.1444 15.375H20.818ZM8.02123 11.3021L5.23475 16.875H10.8077L8.02123 11.3021ZM8.02123 20.875C6.48466 20.875 5.19635 19.8081 4.85804 18.375H11.1844C10.8461 19.8081 9.5578 20.875 8.02123 20.875Z"
      style={{ fill: ALOE_100 }}
    />
  </svg>
);
