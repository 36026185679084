import {
  IconLink,
  OwnUpExtraSmallHeadlineBook,
  OwnUpLargeBody,
  OwnUpLargeBodyMedium,
  ownUpMidtoneTheme,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import { ArrowRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/arrow-right';
import React, { useCallback, useState } from 'react';
import { ConnectedModal, Container } from './elements';

export const ConnectWithLender = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const clickHandler = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setModalOpen(true);
  }, []);

  return (
    <OwnUpThemeProvider theme={ownUpMidtoneTheme}>
      <Container>
        <OwnUpExtraSmallHeadlineBook variant="h1">
          Not interested in talking to an advisor?
        </OwnUpExtraSmallHeadlineBook>
        <OwnUpLargeBody variant="body1">
          We&apos;ll find you the perfect lender regardless of what you decide. If you&apos;d prefer
          to skip the consult, a lender will reach out shortly.
        </OwnUpLargeBody>
        <IconLink link="#" icon={<ArrowRightIcon />} onClick={clickHandler}>
          <OwnUpLargeBodyMedium variant="body1">Connect with your lender now</OwnUpLargeBodyMedium>
        </IconLink>
        <ConnectedModal open={modalOpen} setOpen={() => setModalOpen(false)} />
      </Container>
    </OwnUpThemeProvider>
  );
};
